@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Alegreya:400,700,900);
@font-face {
	font-family: 'GothamHTF-Book';
	src:  url('../fonts/GothamHTF-Book.woff') format('woff'), 
	url('../fonts/GothamHTF-Book.ttf')  format('truetype'), 
	url('../fonts/GothamHTF-Book.svg#GothamHTF-Book') format('svg');
	font-weight: normal;
	font-style: normal;
}
body{
	margin: 0;
	padding: 0;
	background: #fff;
	color: #000;
	font-family: 'Raleway', sans-serif;
	font-size: 13px;
}
h1,h2,h3,h4,h5,h6{
	font-family: 'Alegreya', sans-serif;
}
h1{font-size: 22px;color:#2f2f2f;text-align: left;margin-top: 0px;margin-bottom:20px;}
h1:after, h2:after{
	position: relative;
	display: block;
	width: 110px;
	height: 4px;
	content: '';
	background: #616172;
	margin-top:5px !important;
}
h2:after{margin:0 auto;}
h2{font-size: 20px;color:#2f2f2f;text-align: left;margin-top: 0px;text-align: center;margin-bottom:30px;}
h3{font-size: 18px;color:#2f2f2f;text-align: left;margin-top: 0px;}
p{
	font-family: 'Raleway', sans-serif;
	font-size:13px;
	line-height:19px;
	color: #444444;
	letter-spacing: 1px;
	text-align: justify;
}
.clear{clear: both;}
a, a:hover{text-decoration: none;}
ul{padding: 0;margin:0;}
.wrape_header{
	width: 100%;
	height: auto;
	min-height:200px;
	background: #ddd;
}
.header-top{
	width:100%;
	height: auto;
	min-height: 40px;
	background: #221434;
}
.social ul{list-style: none;margin:0;padding:0;width:187px}
.social ul li{display: inline-block;margin-right: 10px;margin-top: 14px;}
.social ul li:last-child{margin-right: 0px}
.facebook:before{
	width:7px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png) -3px -3px no-repeat;
}
.twitter:before{
	width:16px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png)-14px -3px no-repeat;
}
.instagram:before{
	width:16px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png)-33px -3px no-repeat;
}
.google:before{
	width:16px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png)-51px -3px no-repeat;
}
.pinterest:before{
	width:16px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png)-70px -3px no-repeat;
}
.linkedin:before{
	width:16px;
	height: 16px;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png) -88px -3px no-repeat;
}
.tripadvisor:before{
    width:19px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -3px -22px no-repeat;
}
.language{float: right; position: relative;}
.language p{color:#fff;font-weight: 600;margin:0px;line-height:40px;cursor: pointer;}
.language p:before{
	width:16px;
	height: 16px;
	position: relative;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png) -110px -3px no-repeat;
	top:3px;
	margin-right:15px
}
/*.language p:after{
	width:7px;
	height: 5px;
	position: relative;
	display: inline-block;
	content: '';
	background: url(../uploads/images/all-icon.png) -130px -3px no-repeat;
	top:-2px;
	margin-left:15px
}*/
.language ul {
    position: absolute;
    background: #313536;
    top: 40px;
    z-index: 999999;
    list-style: none;
    margin: 0;
    right: 0px;
    display: none;
    padding: 5px;
    width: 120px;
    text-align: center;
}
.language ul li{margin:2px 0px;}
.language ul li a{color:#9f9f9f;font-weight: 400;letter-spacing:0.5px;}
.header{
	width:100%;
	height: auto;
	min-height: 160px;
	background:#FFF url(../uploads/images/header-bg.jpg) repeat-x;
	background-size: 300px;
}
.disable{display: none !important;}
.scroll-header{
	height:70px;
	width:100%;
	background:url(../uploads/images/scroll-header-bg.jpg) repeat-x;
	background-size: 300px;
	position:fixed;
	top:0;
	left:0;
	z-index:99999;
	-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
	-moz-box-shadow:    0px 3px 5px 0px rgba(0, 0, 0, 0.3);
	box-shadow:         0px 3px 5px 0px rgba(0, 0, 0, 0.3);
	-webkit-transition-duration: 0.5s;
	-moz-transition-duration: 0.5s;
	-ms-transition-duration: 0.5s;
	-o-transition-duration: 0.5s;
	transition-duration: 0.5s;
}
.scroll-menu{margin-top:10px;}
.scroll-logo img{
	margin: 0 auto;
    display: block;
    margin-top: 7px;
}
.logo{padding:10px 0px;}
.logo img{margin:0 auto;}
/*============== Menu =============*/
.navbar-collapse{
	padding:0px;
	border-top: 0px;
	box-shadow: inset 0 0 0 rgba(255, 255, 255, 0.1);
}
.navbar-header{

}
.navbar-right{margin-right:0px;}
.navbar-nav{margin:0px;}
.navbar-nav > li{
	margin-left:39px;
}
.navbar-nav > li:first-child{
	margin-left:0px;
}
.navbar-nav > li > a{
    font-size:11px;
    font-weight:600;
    text-transform: uppercase;
    color:#080808;
    padding:15px 0px 6px;
    letter-spacing:0.5px;
}
.navbar-nav > li > a.menuactive{color:#3e008e}
.nav > li > a:hover, .nav > li > a:focus{
    background: none !important;
}
.navbar-toggle{margin-top:8px;padding:10px;}
.navbar-toggle .icon-bar{
    width: 35px;
    height: 4px;
    background: #292b2c;
    border-radius:2px;
}

.navbar-toggle .icon-bar + .icon-bar{margin-top:6px;}
.caret{display: none;}
/*============== slideshow =============*/
.slideshow{
	width:100%;
	height: auto;
	background: #505267;
}
.wrape_content{
	width: 100%;
	height: auto;
	background: #fff;
}
.content{
	width:100%;
	height: auto;
	background: #fff;
	position: relative;
	margin-top: -75px;
	z-index: 10;
	padding-bottom: 30px;
}
.content img{
    display: block;
    max-width: 100%;
    height: auto;
}
.content p{margin-bottom:15px;}
.content ul{list-style: none;}
.content ul li{line-height:21px;letter-spacing: 1px;}
.content ul li:before{
    width: 6px;
    height: 6px;
    position: relative;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -205px -3px no-repeat;
    top: -1px;
    margin-right: 10px;
}
.home-img img{margin: 0 auto;}
.wrape_feature{
	width: 100%;
	height: auto;
	padding: 30px 0px;
	background: #f5f5f5;
}
.home-feature{
	background: #fff;
}
.f-desc{padding:50px 45px 0px 45px;}
.f-desc h1{
	margin-bottom:30px;
}
.f-desc h1 a{
	color:#2f2f2f;
}
.read-more{width:135px;margin:30px 0px;}
.read-more a{
    background: #3e008e;
    border: none;
    display: block;
    padding: 11px 15px 9px;
    color: #fff;
    -webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	text-transform: uppercase;
	text-align: center;
	font-size: 12px;
	font-weight:600;
}
.wrape_spot_img{
	width: 100%;
	height: auto;
	padding: 30px 0px;
	min-height: 500px;
	background: #fff;
}
.home-spotlight{position: relative;overflow: hidden;}
.home-spotlight img {
  width: 100%;
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(0);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.home-spotlight:hover img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.home-spot-box{
	position: absolute;
    width: 100%;
    height: 100%;
    background: url(../uploads/images/spot-light-bg.png) repeat-x left bottom;
    z-index: 3;
    top: 0;
}
.home-spot-box h3{
	position: absolute;
    bottom: 10px;
    padding: 0px 25px;
    color: #fff;
}
.home-full-feature{padding:0px 50px 20px;}
.home-full-feature img{margin-bottom:30px;}


/*================= BE Form ===================*/
.be-form{
	padding:30px 10px 50px 10px;
}
.form-control {
	font-weight: 600 !important;
	font-size: 13px !important;
}
input::-webkit-input-placeholder {
color: #444 !important;
}
 
input:-moz-placeholder { /* Firefox 18- */
color: #444 !important;  
}
 
input::-moz-placeholder {  /* Firefox 19+ */
color: #444 !important;  
}
 
input:-ms-input-placeholder {  
color: #444 !important;  
}
.slt-box{
	width:100%;
	padding:8px 5px;
	color:#444;
	border: 1px solid #CECECE;
	border-radius: 0px;
	font-weight: 600;
	font-size:13px;
    background: #fff;
}
.sm-button{
	width: 85%;
    float: right;
    background: #cf9f51;
    border: none;
    padding: 8px;
    color: #fff;
    -webkit-border-radius: 15px;
	-moz-border-radius: 15px;
	border-radius: 15px;
	text-transform: uppercase;
}


/*============== footer =============*/
.footer{
    width: 100%;
    min-height: 200px;
    background: #160c25;
}
.topfooter{
    padding:50px 0px;
    background: #221435;
}
.topfooter h3{text-transform: uppercase;font-size:18px;color:#9f9f9f;margin-bottom:20px;}
.topfooter p{color:#9f9f9f;margin-bottom:5px;}
.f-logo1{text-align: left;}
.f-logo2{text-align: left;margin-top:40px;}
.f-logo1:before{
    width: 102px;
    height:90px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -2px -46px no-repeat;
}
.f-logo2:before{
    width: 92px;
    height:82px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -125px -50px no-repeat;
}
.q-link1 ul{list-style: none;}
.q-link ul{list-style: none;}
.q-link ul li{font-weight:300;margin-bottom:5px;}
.q-link ul li a{color:#f2f2f2;}
.topfooter ul li a{
	color:#9f9f9f;
	letter-spacing: 1px;
	font-size: 13px;
}
.topfooter ul li a:hover{
	text-decoration: none;
	color: #867b70;
}
.topfooter ul li a.menuactive{
	color: #867b70;
}
.footer-bottom{
    color: #867b70;
    min-height: 40px;
    background: #160c25;
    font-size: 12px !important;
    line-height:40px;
}
.footer-bottom a{color:#867b70;}
.footer-bottom a:hover{}

.newsletter h3{
	margin-top: 20px;
	margin-bottom: 10px;
}
.text-newsletter{
	padding: 5px 10px;
	width: 100%;
}
.btnsignup{
	padding: 6px 13px;
	text-transform: uppercase;
	color: #fff;
	background: #28256c;
	border: 0px;
	float: left;}
/*===============Room Feature===========*/
.room-feature{margin-bottom: 30px;background: #fff;}
.room-desc{padding:30px;}
.room-desc h1 a{color:#2f2f2f;}

/*===============Resturant Feature===========*/
.resturant-feature{
    background: #fff;
}
.resturant-desc{padding:50px 45px 0px 45px;}
.resturant-desc h1{
    margin-bottom:20px;
}
.resturant-desc h1 a{
    color:#2f2f2f;
}
.resturant-desc p.time{margin-bottom: 4px;font-style: italic;font-size:14px;}
.resturant-desc p.size{margin-bottom: 20px;font-style: italic;font-size:14px;}
.time:before{
    width:16px;
    height:16px;
    position: relative;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-152px -3px no-repeat;
    top:3px;
    margin-right:15px;
}
.size:before{
    width:19px;
    height:16px;
    position: relative;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-180px -3px no-repeat;
    top:4px;
    margin-right:11px;
}

/*===============Gallery===========*/
.g-offset{margin-left:-30px;margin-right:-30px;}
.row-offset {
    margin: 0px -2px;
}
.row-inset-gal {
    width: auto!important;
    margin: 0px -13px;
    margin-bottom: 4px;
}
/*==============Contact Form==============*/
.add, .tel, .mail{
    background:#f5f5f5;
    padding: 40px 30px;
    margin-top:30px;
}
.add p, .tel p, .mail p{
    text-align: center;
}


/*========= contact form ========*/

.wr_contact_form{
    width:100%;
    padding:0px 150px;
    margin-top:100px;
}
.slt, .txt, .txtarea {
    display: block;
    width: 100%;
    height: auto !important;
    padding: 18px 12px;
    font-size: 12px;
    color: #444444;
    border-radius: 1px;
    background-color: #F5F5F5;
    background-image: none;
    border: none;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-bottom: 1px solid #DCDCDC;
    margin-top:5px;
    margin-bottom:15px;
    font-weight: 400 !important;
}
.label{
    font-size: 16px;
    color: #000;
    padding:0px;
    font-weight:500;
    text-transform: capitalize;
    color:#2f2f2f;
    font-family: 'Alegreya', sans-serif;
}
.chapcha{margin:0 auto;width:168px;}
.wr_btn{width:168px;height: auto;margin: 0 auto;}
.btnsubmit{
    background:#cf9f51;
    padding: 15px 25px;
    border:0px;
    font-size: 18px;
    color: #fff;
    text-transform: capitalize;
    font-family: 'Alegreya', sans-serif;
}

.r_amenity ul{
    list-style: none;
}
.r_amenity ul li{
    font-size: 13px;
    line-height: 40px;
    color: #444444;
    letter-spacing: 1px;
    text-align: center;
    border-bottom: 1px solid #F1F1F1;
}
.r_amenity ul li:before{display: none;}
.r_amenity ul li:first-child{border-top: 1px solid #F1F1F1;}
.book_now{
    display: block;
    text-align: center;
    background: #3e008e;
    padding: 10px;
    color: #fff;
    font-size:14px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top:25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
}
.book_now:hover{color:#fff;}
.sidebar h3{
    text-align: center;
    background: #1F1F1F;
    padding: 20px 0px;
    color: #cf9f51;
}
.sidebar h3:after{
    width: 133px;
    height: 2px;
    content: '';
    display: block;
    position: relative;
    background: #cf9f51;
    margin: 0 auto;
    margin-top: 5px
}
.sidebar ul{
    list-style: none;
}
.sidebar ul li{
    font-size: 12px;
    color: #444444;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    border-bottom: 1px solid #F1F1F1;
}
.sidebar ul li:before{display: none;}
.sidebar ul li a{
    padding:20px;
    display: block;
    color: #444;
}
.sidebar ul li a:hover, .sidebar ul li a.menuactive{color:#cf9f51;}

/*======== Footer social ========*/
.f_facebook:before{
    width:7px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -3px -148px no-repeat;
}
.f_twitter:before{
    width:16px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-14px -148px no-repeat;
}
.f_instagram:before{
    width:16px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-33px -148px no-repeat;
}
.f_google:before{
    width:16px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-51px -148px no-repeat;
}
.f_pinterest:before{
    width:16px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png)-70px -148px no-repeat;
}
.f_linkedin:before{
    width:16px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -88px -148px no-repeat;
}
.f_tripadvisor:before{
    width:19px;
    height: 16px;
    display: inline-block;
    content: '';
    background: url(../uploads/images/all-icon.png) -3px -168px no-repeat;
}

/*=========Press======*/
.content-press{
    margin: 20px 0px;
}
.wrap-press h3{text-transform: uppercase;}
.wrap-press p{text-align: left;}
.date-press{font-size: 10px;text-transform: uppercase;}
.read-articles a{color: #742314;text-transform: uppercase;font-size: 11px;}
.read-articles a:hover{color: #903c2d;}
.press-description{padding: 0px 15px;}
.wrap-press{border-bottom: 1px solid #d8cdcd;padding-bottom: 50px;margin-bottom: 50px;}

/*=== Location ===*/

.list-icon{
    text-align: center;
    padding: 0 0 20px;
}
.list-icon ul{
    padding: 0;
}
.list-icon ul li{
    width: 150px;
    text-align: center;
    display: inline-block;
}
.list-icon .icon {
    border-radius: 50%;
    color: #c19c78;
    display: block;
    font-size: 30px;
    margin: 0 auto;
    height: 50px;
    width: 50px;
    padding: 9px 0 0;
    border: 1px solid #c19c78;
}
.btn-menu{
    text-align: -webkit-center;
}

/*################## Preaking Point ##############################*/
@media (max-width: @screen-xs-max) {
    .list-icon ul li{width: 100px;}
    .social ul{margin:0 auto; text-align: -webkit-center;}
    .language{float:none;width:180px;margin:0 auto;}
	.f-desc{padding:0px 15px;}
	.home-full-feature{padding:0px;}
	.f-img{margin-bottom:30px;}
	.navbar-nav > li > a{text-align: center;color:#9F9F9F;border-bottom:1px solid #313131;}
	.content{margin-top:-30px;}
	.navbar-collapse{
		background: #292b2c;
	}
    /*.menubar-sm{width: 735px;margin:0 auto;}*/
	.navbar-nav > li{margin:0px;}
	.topfooter, .topfooter h3, .topfooter p{text-align: center;}
	.f-logo1{text-align: center;}
	.f-logo2{text-align: center;padding-bottom:30px;margin-top:20px;border-bottom: 1px solid #282A2B;}
	.f-contact{padding:30px 0px;border-top: 1px solid #444748;border-bottom: 1px solid #282A2B;}
	.q-link{padding-top:30px;border-top: 1px solid #444748;}
	.footer-bottom{text-align: center;line-height: 20px;padding:20px 0px;}
	.home-spotlight{margin-bottom:20px;}
	.sm-button{width:100%;}
    .pull-right{float:none !important;}
    .resturant-img{margin-bottom:30px;}
    .resturant-desc{padding:0px 15px;}
    .wr_contact_form{padding:0px;}
    .r_amenity h2{margin-top:30px;}
    .rsArrowIcn{display:none;}
    .press-description{padding: 15px 0px;}
    .wrap-press{margin-bottom: 15px;border-bottom: 1px solid #d8cdcd;padding-bottom: 15px;}
    .navbar-nav > li > a.menuactive, .navbar-nav > li > a:hover{
    	color: #cf9f51;
    }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.container{width:100%;padding:0px -15px;margin: 0 auto;}
	.home-img{padding-right:60px;}
	.menubar-sm{width:735px;margin:0 auto;}
	.navbar-nav > li {margin-left:28px;}
	.navbar-nav > li > a{
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        overflow: hidden;
    }
    .navbar-nav > li > a:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        background: #3e008e;
        height: 2px;
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .navbar-nav > li > a:hover:before, .navbar-nav > li > a:focus:before, .navbar-nav > li > a.menuactive:before {
       -webkit-transform: translateY(0);
       transform: translateY(0);
    }
    .f-contact, .q-link{margin-top:40px;}
    .sm-button{width:100%;}
    .f-desc{padding-top:50px;}
    .resturant-desc{padding-top:50px;}
    .topfooter, .topfooter h3, .f-logo1, .f-logo2{text-align: center;}
    .topfooter p{text-align: center;}
    .f-social ul{margin:0 auto;}
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
	.container{width:100%;padding:0px -15px;margin: 0 auto;}
	.home-img{padding-right:60px;}
	.menubar-sm{width:810px;margin: 0 auto;}
	.navbar-nav > li > a{
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        overflow: hidden;
    }
    .navbar-nav > li > a:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        background: #cf9f51;
        height: 2px;
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .navbar-nav > li > a:hover:before, .navbar-nav > li > a:focus:before, .navbar-nav > li > a.menuactive:before {
       -webkit-transform: translateY(0);
       transform: translateY(0);
    }
}
@media (min-width: @screen-lg-min) {
	.home-img{padding-right:60px;}
	.menubar-sm{width:810px;margin: 0 auto;}
	.navbar-nav > li > a{
        display: inline-block;
        vertical-align: middle;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        overflow: hidden;
    }
    .navbar-nav > li > a:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        bottom: 0;
        background: #cf9f51;
        height: 2px;
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .navbar-nav > li > a:hover:before, .navbar-nav > li > a:focus:before, .navbar-nav > li > a.menuactive:before {
       -webkit-transform: translateY(0);
       transform: translateY(0);
    }
}

/*icon addon*/
.form-group{margin-bottom:10px;}
.center-block {
    float: none;
    margin-left: auto;
    margin-right: auto;
}
.input-group .icon-addon .form-control {
    border-radius: 0;
}
.icon-addon {
    position: relative;
    color: #b88e65;
    display: block;
}
.icon-addon:after, .icon-addon:before {
    display: table;
    content: " ";
}
.icon-addon:after {
    clear: both;
}
.icon-addon.addon-md .glyphicon, .icon-addon .glyphicon, .icon-addon.addon-md .fa, .icon-addon .fa {
    position: absolute;
    z-index: 2;
    left: 10px;
    font-size: 14px;
    width: 20px;
    margin-left: -2.5px;
    text-align: center;
    padding: 9px 0;
}
.icon-addon.addon-lg .form-control {
    line-height: 1.33;
    height: 46px;
    font-size: 18px;
    padding: 10px 16px 10px 40px;
}
.icon-addon.addon-sm .form-control {
    height: 30px;
    padding: 5px 10px 5px 28px;
    font-size: 12px;
    line-height: 1.5;
}
.icon-addon.addon-lg .fa, .icon-addon.addon-lg .glyphicon {
    font-size: 18px;
    margin-left: 0;
    left: 11px;
    top: 4px;
}
.icon-addon.addon-md .form-control, .icon-addon .form-control {
    line-height:30px;
    float: left;
    font-weight: normal;
    text-indent:25px !important;
    border-radius: 0;
    border: 1px solid #CECECE;
}
.icon-addon.addon-sm .fa, .icon-addon.addon-sm .glyphicon {
    margin-left: 0;
    font-size: 12px;
    left: 5px;
    top: -1px
}
.icon-addon .form-control:focus + .glyphicon, .icon-addon:hover .glyphicon, .icon-addon .form-control:focus + .fa, .icon-addon:hover .fa {
    color: #D0B73E;
}